import { Component, effect, ViewChild } from "@angular/core";
import { AssetLocation, WorkOrder } from "src/app/models/order-model";
import { ScanService } from "../../services/scan.service";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { noDataAvlText } from "src/assets/config/constants";
import { OrderService } from "src/app/features/orders/services/order.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-asset-details",
  templateUrl: "./asset-details.component.html",
  styleUrls: ["./asset-details.component.scss"],
})
export class AssetDetailsComponent {
  btnconfig = {
    name: "Edit Order",
    url: "",
  };
  assetDetails: AssetLocation | undefined;
  lastServiceDetails: WorkOrder | undefined;
  nextServiceDetails: WorkOrder | undefined;
  serviceDetails: WorkOrder[] = [];  
  updatedServiceDetails: WorkOrder[] = []; 
  contractId: string | undefined = "";
  noDataAvlText = noDataAvlText;
  isEditOrderEnabled: boolean = false;

  paginatedServiceDetails: WorkOrder[];
  pageSize = 5;
  currentPage = 0;

  daysCount: number = 1;

  action: string = "upcoming";
  unitNumber:string;
  limit: number = 90;
  tabValue =0;

  constructor(
    public scanService: ScanService,
    private router: Router,
    private _Activatedroute: ActivatedRoute,
    private toastr: ToastrService,
    private orderService: OrderService,
    private profileService: ProfileService
  ) {
    effect(() => {
      const details = this.scanService.assetDetailsSignal();
      this.unitNumber =details?.assetDetails.unitNumber ||'';
      this.assetDetails = details?.assetDetails;
      this.contractId = details?.assetDetails.contractId;
      this.updatedServiceDetails = details?.upcomingServices?.length ? details.upcomingServices : details?.completedServices ||[];
      if (this.updatedServiceDetails.length) {
        this.serviceDetails.push(...this.updatedServiceDetails);
      }
      this.paginatedServiceDetails = this.serviceDetails.slice(0,5);
      this.nextServiceDetails = details?.upcomingServices[0];
      this.btnconfig.url = `orders/edit-order/${details?.assetDetails?.contractId}`;
      if (details == null) {
        this._Activatedroute.params.pipe(take(1)).subscribe((params) => {
          if (params.unitNumber && params.unitNumber.length >= 8) {
            this.unitNumber = params.unitNumber;
           this.getAssetDetailsByUnitNumber(params.unitNumber);
          } else {
            router.navigate(["/home"]);
          }
        });
      }
    });
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.orderService.orderSummaryBehaviorSubject.next(null);
    if (
      this.profileService.selectedAccount().myussModules?.myussCasesEnabled &&
      this.profileService.selectedAccount().myussModules?.myussOrdersEnabled
    ) {
      this.isEditOrderEnabled = true;
    }
  }

  onTabChanged(index: number) {
    this.paginator?.firstPage();
    this.limit=90;
    this.serviceDetails =[];
    if (index === 0) {
      this.tabValue =0;
      this.action ='upcoming';
      this.getAssetDetailsByUnitNumber(this.unitNumber);
    } else {
      this.tabValue =1;
      this.action ='completed'
      this.getAssetDetailsByUnitNumber(this.unitNumber);
    }
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.paginateData();
  }

  paginateData() {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedServiceDetails = this.serviceDetails.slice(
      startIndex,
      endIndex
    );
  }

  clickPrev() {
    this.limit = this.limit + 90;
    this.action = "completed";
    this.getAssetDetailsByUnitNumber(this.unitNumber);
  }

  clickNext() {
    this.limit = this.limit + 90;
    this.action = "upcoming";
    this.getAssetDetailsByUnitNumber(this.unitNumber);
  }

  getAssetDetailsByUnitNumber(unitNumber:string) {
    this.scanService
      .getAssetDetailsByUnitNumber(unitNumber, this.action, this.limit)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.scanService.setAssetDetails(res);
        } else {
          this.toastr.error("No asset found with this unit number");
          this.router.navigate(["/home"]);
        }
      });
  }
}
